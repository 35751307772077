import Layout from "components/Layout";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Home.scss";
import {
  ambienceLogo,
  animationImg,
  champdogLogo,
  dodgeCineLogo,
  hubScapeLogo,
  iconLogo,
  jaggedEdgeLogo,
  motionGraphicsImg,
  npLogo,
  okGsLogo,
  orangeSpotProductionsLogo,
  serviceImg1,
  serviceImg2,
  serviceImg3,
  serviceImg4,
  serviceImg5,
  sntnlLogo,
  vfxImg,
  videoProductionImg,
  visionImg,
} from "images";
import CountUp from "react-countup";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const Home = () => {
  return (
    <>
      <Layout>
        <div className="hero">
          <div className="video text-center">
            <iframe
              src="https://customer-8vqpptbz2xqanzj3.cloudflarestream.com/1d195f2e9ab8d85ceea7289e9a5d294d/iframe?autoplay=true&muted=true&loop=true&controls=false"
              width="100%"
              height="100%"
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowfullscreen="true"
              title="main-video"
            ></iframe>
          </div>
          <Container className="position-relative">
            <Row>
              <Col xs={12}>
                <div className="hero-container">
                  <h1>Luma pixels</h1>
                  <h5 className="mb-4">Ideation. Creation.</h5>
                  <a
                    href="mailto:Contactlumapixels@gmail.com"
                    className="contact-btn"
                  >
                    Contact Us
                  </a>
                  <div className="counter">
                    <div className="client-served">
                      <h6>
                        <CountUp
                          end={300}
                          suffix="+"
                          enableScrollSpy={true}
                          duration={3}
                        />
                      </h6>
                      <p>Clients Served</p>
                    </div>
                    <div className="creativity-minutes">
                      <h6>
                        <CountUp
                          end={5000}
                          suffix="+"
                          enableScrollSpy={true}
                          duration={3}
                        />
                      </h6>
                      <p>Creativity Minutes</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="black-bg">
          <div className="clients py-5" id="clients">
            <Container>
              <Row className="row-cols-md-5 row-cols-sm-4 row-cols-2 align-items-center">
                <Col className="my-4">
                  <div className="client-item">
                    <img src={dodgeCineLogo} alt="" className="img-fluid" />
                  </div>
                </Col>
                <Col className="my-4">
                  <div className="client-item">
                    <img
                      src={orangeSpotProductionsLogo}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Col>
                <Col className="my-4">
                  <div className="client-item">
                    <img src={npLogo} alt="" className="img-fluid" />
                  </div>
                </Col>
                <Col className="my-4">
                  <div className="client-item">
                    <img src={champdogLogo} alt="" className="img-fluid" />
                  </div>
                </Col>
                <Col className="my-4">
                  <div className="client-item">
                    <img src={sntnlLogo} alt="" className="img-fluid" />
                  </div>
                </Col>
                <Col className="my-4">
                  <div className="client-item">
                    <img src={iconLogo} alt="" className="img-fluid" />
                  </div>
                </Col>
                <Col className="my-4">
                  <div className="client-item">
                    <img src={ambienceLogo} alt="" className="img-fluid" />
                  </div>
                </Col>
                <Col className="my-4">
                  <div className="client-item">
                    <img src={okGsLogo} alt="" className="img-fluid" />
                  </div>
                </Col>
                <Col className="my-4">
                  <div className="client-item">
                    <img src={hubScapeLogo} alt="" className="img-fluid" />
                  </div>
                </Col>
                <Col className="my-4">
                  <div className="client-item">
                    <img src={jaggedEdgeLogo} alt="" className="img-fluid" />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="why-choose-us mb-5" id="whychooseus">
            <Container>
              <Row className="justify-content-end why-choose-us-content">
                <Col lg={6}></Col>
                <Col lg={6}>
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 4500,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div className="why-choose-us-detail text-center pb-5">
                        <p className="px-lg-5 px-4">
                          Awarded the "Best VFX" brand at the 8th Delhi Short
                          Film Festival for "Cosmic Light" in 2019 and
                          prestigious recognition at the Berlin Film Festival
                          for "Neo Guwahati" in 2021, our expertise and mastery
                          are unrivaled.
                        </p>
                        <p>
                          <a
                            href="https://youtu.be/a50IX_idO58"
                            target="_blank"
                            rel="noreferrer"
                          >
                            https://youtu.be/a50IX_idO58
                          </a>
                        </p>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="why-choose-us-detail text-center pb-5">
                        <p className="px-lg-5 px-4">
                          With over 140 VFX shots in the English Indie Feature
                          "Monsternado," our unwavering dedication to meeting
                          deadlines while delivering outstanding visual effects,
                          makes us the ideal choice for your next project.
                        </p>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="why-choose-us-detail text-center pb-5">
                        <p className="px-lg-5 px-4">
                          Embracing the unique demands of the musical "The
                          Shower Song" , we delivered extraordinary character
                          creation and captivating visual effects. Our
                          unwavering commitment to enhancing the message of the
                          production sets us apart.
                        </p>
                        <p>
                          <a
                            href="https://theshowersong.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            https://theshowersong.com/
                          </a>
                        </p>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="why-choose-us-detail text-center pb-5">
                        <p className="px-lg-5 px-4">
                          Driven by design mindset, we created a precise and
                          information-driven video for Hubscape London. Our
                          team's mastery of subtle sound effects and seamless
                          transitions allows us to unleash the full creative
                          brilliance into extraordinary display of motion
                          graphics.
                        </p>
                        <p>
                          <a
                            href="https://youtu.be/xtejXKuKqto"
                            target="_blank"
                            rel="noreferrer"
                          >
                            https://youtu.be/xtejXKuKqto
                          </a>
                        </p>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="why-choose-us-detail text-center pb-5">
                        <p className="px-lg-5 px-4">
                          Collaborating with the very talented Rehaa on her
                          music video "Ami," we touched the bases of ideation,
                          planning, and execution and meticulous attention to
                          detail, we brought "Ami" to life.
                        </p>
                        <p>
                          <a
                            href="https://youtu.be/dnUPKuN-exM"
                            target="_blank"
                            rel="noreferrer"
                          >
                            https://youtu.be/dnUPKuN-exM
                          </a>
                        </p>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="service-we-offer my-5 py-5" id="services">
            <Container>
              <Row>
                <Col xs={12} className="mb-5 pb-4">
                  <div className="heading">
                    <h2>Services We Offer</h2>
                  </div>
                </Col>
                <Col lg={5}>
                  <Row>
                    <Col xs={12} className="mb-4">
                      <Swiper
                        pagination={{
                          type: "fraction",
                        }}
                        navigation={true}
                        autoplay={{
                          delay: 4500,
                          disableOnInteraction: false,
                        }}
                        modules={[Pagination, Navigation, Autoplay]}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                          <div className="service-img">
                            <img
                              src={serviceImg1}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="service-img">
                            <img
                              src={serviceImg2}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="service-img">
                            <img
                              src={serviceImg3}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="service-img">
                            <img
                              src={serviceImg4}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="service-img">
                            <img
                              src={serviceImg5}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </Col>
                  </Row>
                </Col>
                <Col lg={7}>
                  <Row>
                    <Col sm={6} className="mb-4">
                      <div className="service-item position-relative">
                        <div className="video">
                          <iframe
                            src="https://customer-8vqpptbz2xqanzj3.cloudflarestream.com/0579baebd5f034cba64b00f578d73400/iframe?autoplay=true&muted=true&loop=true&controls=false"
                            width="100%"
                            height="100%"
                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                            allowfullscreen="true"
                            title="video1"
                          ></iframe>
                        </div>
                        <div className="service-img mb-md-5 mb-3 pb-md-2">
                          <img
                            src={videoProductionImg}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="service-content">
                          <p className="mb-0">End-to-End video production</p>
                        </div>
                      </div>
                    </Col>
                    <Col sm={6} className="mb-4">
                      <div className="service-item position-relative">
                        <div className="video">
                          <iframe
                            src="https://customer-8vqpptbz2xqanzj3.cloudflarestream.com/32b3d3aebd8bfc2448fbf187931b3ed2/iframe?autoplay=true&muted=true&loop=true&controls=false"
                            width="100%"
                            height="100%"
                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                            allowfullscreen="true"
                            title="video2"
                          ></iframe>
                        </div>
                        <div className="service-img mb-md-5 mb-3 pb-md-2">
                          <img
                            src={motionGraphicsImg}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="service-content">
                          <p className="mb-0">
                            Motion <br /> Graphics
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col sm={6} className="mb-4">
                      <div className="service-item position-relative">
                        <div className="video">
                          <iframe
                            src="https://customer-8vqpptbz2xqanzj3.cloudflarestream.com/3c43e0166a11d2a2034e4499dcd96232/iframe?autoplay=true&muted=true&loop=true&controls=false"
                            width="100%"
                            height="100%"
                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                            allowfullscreen="true"
                            title="video3"
                          ></iframe>
                        </div>
                        <div className="service-img mb-md-5 mb-3 pb-md-2">
                          <img src={vfxImg} alt="" className="img-fluid" />
                        </div>
                        <div className="service-content">
                          <p className="mb-0">
                            VFX <br /> Services
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col sm={6} className="mb-4">
                      <div className="service-item position-relative">
                        <div className="video">
                          <iframe
                            src="https://customer-8vqpptbz2xqanzj3.cloudflarestream.com/fa760c0e282424d5ef59435f9077cc63/iframe?autoplay=true&muted=true&loop=true&controls=false"
                            width="100%"
                            height="100%"
                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                            allowfullscreen="true"
                            title="video4"
                          ></iframe>
                        </div>
                        <div className="service-img mb-md-5 mb-3 pb-md-2">
                          <img
                            src={animationImg}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="service-content">
                          <p className="mb-0">Animation</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="our-team py-5 position-relative" id="ourteam">
            <Container>
              <Row className="align-tems-center">
                <Col lg={7}>
                  <div className="py-5">
                    <div className="heading">
                      <h3>About Us</h3>
                    </div>
                    <div className="our-team-content">
                      <p className="mb-0">
                        We are a video production company specializing in
                        diverse entertainment content, visual interactions, and
                        creative artistry. Headquartered in Guwahati, Assam;
                        Luma Pixels is founded on the culture of originality,
                        innovation and collaboration. The company’s clients
                        numbers some of the world’s top filmmakers , directors,
                        who return to us time and again as trusted partners and
                        creative allies. The team works closely with clients
                        throughout every phase of the creative process, from VFX
                        productions, cinematography, animation, and
                        post-productions to produce visually stunning and
                        engaging content that resonates with the masses
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={5}>
                  {/* <div className="team-img">
                    <img src={teamImg} alt="" className="img-fluid" />
                  </div> */}
                </Col>
              </Row>
            </Container>
          </div>

          <div className="our-vision my-5 py-5" id="ourvision">
            <Container>
              <Row>
                <Col>
                  <div className="our-vision-content">
                    <div className="vision-img">
                      <img src={visionImg} alt="" className="img-fluid" />
                    </div>
                    <div className="our-vision-info">
                      <h2>Our Vision</h2>
                      <p>
                        We envision to empower the young minds of Northeast
                        India with the necessary skills, resources, and
                        opportunities to thrive in the dynamic fields of visual
                        effects, animation, and creative media and fostering an
                        environment that nurtures creativity, innovation, and
                        growth for the youth in this region.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Home;
