import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Header.scss";
import { logo } from "images";

const Header = () => {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const expand = "lg";

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY) {
      setShow(false);
    } else {
      setShow(true);
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
    // eslint-disable-next-line
  }, [lastScrollY]);
  return (
    <>
      <Navbar
        key={expand}
        expand={expand}
        className={`py-3${
          !show ? " hidden" : window.scrollY > 80 ? " scroll" : ""
        }`}
        fixed="top"
      >
        <Container>
          <Navbar.Brand href="#">
            <img src={logo} alt="" className="img-fluid" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <img src={logo} alt="" className="img-fluid" />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1">
                <Nav.Link href="#whychooseus" className="mx-2">
                  Why Choose Us
                </Nav.Link>
                <Nav.Link href="#services" className="mx-2">
                  Services
                </Nav.Link>
                <Nav.Link href="#ourteam" className="mx-2">
                  About Us
                </Nav.Link>
                <Nav.Link href="#ourvision" className="mx-2">
                  Our Vision
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
