import Footer from 'components/Footer'
import Header from 'components/Header'
import React from 'react'

const Layout = (props) => {
  return (
    <>
        <Header/>
        {props.children}
        <Footer/>
    </>
  )
}

export default Layout