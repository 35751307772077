import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./footer.scss";
import { logo } from "images";

const Footer = () => {
  return (
    <>
      <footer className="pt-5">
        <Container className="pt-4">
          <Row className="row-cols-md-6 row-cols-2">
            <Col lg={4} className="mb-4">
              <div className="footer-about">
                <div className="d-flex flex-column flex-md-row align-items-start">
                  <img src={logo} alt="" className="me-md-4 mb-3 mb-md-0" />
                  <div>
                    <h5>LUMA PIXELS</h5>
                    <p>
                      84, Ajanta Path, Beltola, <br /> Guwahati 24, Assam
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="mb-5">
              <div className="footer-column">
                <div className="footer-heading">
                  <h5>Information</h5>
                </div>
                <ul className="list-unstyled">
                  <li>
                    <a href="/#">Services Category</a>
                  </li>
                  <li>
                    <a href="/#">Special Promo</a>
                  </li>
                  <li>
                    <a href="/#">About Us</a>
                  </li>
                  <li>
                    <a href="/#">Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col className="mb-5">
              <div className="footer-column">
                <div className="footer-heading">
                  <h5>Support</h5>
                </div>
                <ul className="list-unstyled">
                  <li>
                    <a href="/#">FAQs</a>
                  </li>
                  <li>
                    <a href="/#">Support Center</a>
                  </li>
                  <li>
                    <a href="/#">Help center</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col className="mb-5">
              <div className="footer-column">
                <div className="footer-heading">
                  <h5>Contact Us</h5>
                </div>
                <ul className="list-unstyled">
                  <li>
                    <a href="/#">Email</a>
                  </li>
                  <li>
                    <a href="/#">Company</a>
                  </li>
                  <li>
                    <a href="/#">Join Us</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col className="mb-5">
              <div className="footer-column">
                <div className="footer-heading">
                  <h5>Social Media</h5>
                </div>
                <ul className="list-unstyled d-flex social-media">
                  <li className="ms-2">
                    <a
                      href="https://m.facebook.com/lumapixels/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li className="ms-2">
                    <a
                      href="https://www.linkedin.com/company/96017940/admin/feed/posts/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li className="ms-2">
                    <a
                      href="https://instagram.com/lumapixels?igshid=YmM0MjE2YWMzOA=="
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <hr />
            </Col>
            <Col xs={12}>
              <p className="text-center">
                All Rights Reserved by Lumapix Creative Studios LLP 2023
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
